<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Tariff Types
      <router-link slot="right" :to="{ name: 'tarifftype.new' }" class="button">
        New Tariff Type
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
        <tarifftype-table />
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import TarifftypeTable from '@/components/SSIPLENGG/TarifftypeTable.vue'
export default {
  name: 'Tarifftypes',
  components: {
    HeroBar,
    TitleBar,
    TarifftypeTable
  },
  computed: {
    titleStack () {
      return ['Admin', 'Tariff Types']
    }
  }
}
</script>
